import React from 'react';
import classname from 'classnames';
import styles from './index.module.scss';

export default function Policy() {
  return (
    <div className={styles.policy}>
      <p className={classname(styles.title, styles.center)}>私隱政策</p>
      <p className={styles.msg}>
        聯亞洲電視經典有限公司（亞洲電視有限公司的全資附屬公司(以下簡稱為「我們」、「我們」、「本公司」或「亞洲電視」)尊重任何使用本公司網頁及移動應用程式的人士或參加亞洲電視的活動或登記使用亞洲電視的服務或網上內容包括但不限於我們合作夥伴的產品及服務(以下簡稱為「服務」)
        的人士的私隱權。我們會確保所收集、傳送、儲存及使用的個人資料將遵照香港特別行政區法例第486章《個人資料(私隱)條例》(以下簡稱為「條例」)的規定處理。若提供個人資料予我們，則被視作同意此私隱政策。
      </p>
      <p className={styles.msg}>「個人資料」一詞含有條例所解釋的意義。</p>
      <p className={styles.msg}>收集個人資料的目的及用途</p>
      <p className={styles.msg}>
        你毋須提供任何個人資料以瀏覽或使用本網頁及移動應用程式。當你參加亞洲電視的活動或登記使用我們的服務或網上內容，我們會收集你的個人資料使我們能夠為你提供服務。你可拒絕向我們提供個人資料，但在此情況下，我們可能無法為你提供服務。閣下提供個人資料，即代表閣下同意我們按本私隱政策聲明使用閣下的個人資料。
      </p>
      <p className={styles.msg} style={{ height: 21 }}></p>

      <p className={styles.keynote}>
        亞洲電視從你收集所得的個人資料可能會用於以下用途（包括但不限於）
      </p>

      <p className={styles.msg}>· 識別你的身分及你開設的任何賬戶</p>
      <p className={styles.msg}>· 為你提供服務</p>
      <p className={styles.msg}>· 核實身分及／或作信貸審查</p>
      <p className={styles.msg}>
        · 確定及核實你享用商品和服務的折扣及促銷的資格
      </p>
      <p className={styles.msg}>
        · 代表你處理申請及更新合作夥伴的產品及服務，包括但不限於保險及財務產品
      </p>
      <p className={styles.msg}>· 代表你處理合作夥伴的產品及服務之保險索償</p>
      <p className={styles.msg}>· 為你處理與服務有關的付款指示或追收欠款</p>
      <p className={styles.msg}>
        ·
        讓本網站儲存你的個人資料，以免你每次購買產品或更新保險產品時需要重新輸入相關資料
      </p>
      <p className={styles.msg}>· 處理訂單、開出賬單及履行訂單</p>
      <p className={styles.msg}>
        · 直接促銷我們的 服務（詳情見「直接促銷」部份）
      </p>
      <p className={styles.msg}>
        · 直接促銷本公司業務夥伴的產品及服務（詳情見「直接促銷」部份）
      </p>
      <p className={styles.msg}>· 為你設計所需服務</p>
      <p className={styles.msg}>· 進行市場研究、統計分析及行為分析</p>
      <p className={styles.msg}>· 進行節目收視調查及分析</p>
      <p className={styles.msg}>· 進行顧客數據分析及分析你的購物喜好</p>
      <p className={styles.msg}>
        ·
        讓你選擇參與我們為你提供的服務的互動功能，包括識別你的朋友，並與他們溝通和分享你的購物體驗
      </p>
      <p className={styles.msg}>· 就我們的服務或你感興趣的商品或服務向你推薦</p>
      <p className={styles.msg}>· 設計本公司網站及內容，迎合你的特定喜好</p>
      <p className={styles.msg}>· 為你提供客戶服務</p>
      <p className={styles.msg}>
        · 處理你的投訴及賬戶查詢，對本公司或任何一方之索償及/或訴訟
      </p>

      <p className={styles.msg}>· 防範及阻止欺詐</p>
      <p className={styles.msg}>· 審計目的</p>
      <p className={styles.msg}>· 按適用法律、規則及規例的規定作出披露</p>
      <p className={styles.msg}>
        · 任何與收集個人資料的原來目的直接有關之其他用途
      </p>
      <p className={styles.msg} style={{ height: 21 }}></p>
      <p className={styles.keynote}>所收集的個人資料類別</p>
      <p className={styles.msg}>
        香港電視所收集的個人資料可能包括（包括但不限於）：
      </p>
      <p className={styles.msg}>
        1.
        你的個人資料及聯絡資料，如姓名、性別、出生日期、身分證號碼、電話號碼、社交媒體連結、電
        郵地址、住址、郵寄地址及/或寄發賬單的地址；
      </p>
      <p className={styles.msg}>2. 你的商業資料，如公司的名稱及職銜；</p>
      <p className={styles.msg}>
        3. 你的賬戶資料，如信用卡賬戶號碼首尾4位數或用戶賬號；
      </p>
      <p className={styles.msg}>4. 你的家庭收入及個人興趣；及</p>
      <p className={styles.msg}>
        5.
        你的電腦或移動裝置IP地址、即時位置資料、瀏覽器設定、瀏覽紀錄及／或其他互聯網記錄的資料；及
      </p>
      <p className={styles.msg}>
        6.
        你的電話簿中包含的電話號碼和電子郵件地址(當你使用我們為你提供的服務的互動功)。當你接受我
        們的服務我們會通知你。當你提供數據給我們，你確認已經從電話簿的聯繫人取得同意。
      </p>
      <p className={styles.msg} style={{ height: 21 }}></p>
      <p className={styles.keynote}>個人資料之保密、披露及保安</p>
      <p className={styles.msg}>
        亞洲電視收集及持有關於你的個人資料均會被保密處理；但若需要按法例的規定或要求而履行法律責任，或為你提供服務，或執行收集個人資料的原來目的或直接有關之目的，本公司有可能會向下述人士披露該等資料（不論其身處香港或香港境外）：
      </p>
      <p className={styles.msg}>
        · 具管轄權的法院、執法機關、或其他政府法定或監管部門、機構或組織
      </p>
      <p className={styles.msg}>
        ·
        本公司之聯繫公司、合作夥伴、參與服務銷售及市場推廣或行政，或提供貨品╱服務的賣家或承辦商、代理或其他服務供應商
      </p>
      <p className={styles.msg}>
        ·
        本公司之合作夥伴包括但不限於保險公司，此可評估及處理保險產品申新、更新申請、保險索償及和解、提供保險服務及/或處理查詢或投訴
      </p>
      <p className={styles.msg}>
        ·
        任何對本公司有保密責任的其他人士，包括已承諾保密該等資料的公司集團成員、資訊科技顧問、資料處理承辦商、審計師、會計師、或律師
      </p>
      <p className={styles.msg}>
        · 銀行、金融機構、保險公司、信用卡發行公司或追收欠款服務公司
      </p>
      <p className={styles.msg}>
        任何由你傳送或登載於我們網站或網站任何部份的問題、意見、建議或資料，除個人資料外，均視作以非保密及非專有資料的形式自願提供予本公司。我們有權自行將該等資料在其他地方使用、複製、披露、傳送、刊載及/或登載，包括但不限於為服務的開發和推廣及為滿足客戶的需要等目的，而將該等資料提供給任何聯繫公司。
      </p>
      <p className={styles.msg}>
        我們有可能會公開共享非個人資料，例如包括但不限於與銷售交易、用戶流量、物流及倉庫績效有關之匿名數據和匯總數據，並有可能會與我們的合作夥伴共享，包括但不限於與現有及潛在商業合作夥伴、提供貨品╱服務的賣家或承辦商、初創企業家及學界等。
      </p>
      <p className={styles.msg}>
        亞洲電視將盡一切合理的努力以確保香港電視持有的所有個人資料均儲存於可靠穩妥及安全的地方。
      </p>
      <p className={styles.msg}>
        我們會盡力將收集到的所有個人資料保密。然而，請你理解我們不可能保證傳輸資料的安全。
      </p>
      <p className={styles.msg}>
        若你購買我們合作夥伴的保險產品或其他產品或服務，你的個人資料將會被傳送至合作夥伴及你的個人資料將會根據合作夥伴的私隱政策被使用、處理及保留。此乃超出我們的控制。詳情請參閱合作夥伴之私隠政策。
      </p>
      <p className={styles.msg} style={{ height: 21 }}></p>
      <p className={styles.keynote}>將個人資料轉移至香港以外地方</p>
      <p className={styles.msg}>
        若在運作上有需要，本公司會將個人資料轉移至香港特別行政區以外的地方以達到收集此等資料之目的，或直接與其有關之目的。
        任何有關的資料轉移都會遵照條例之規定及約束。
      </p>
      <p className={styles.msg} style={{ height: 21 }}></p>
      <p className={styles.keynote}>個人資料的保存</p>
      <p className={styles.msg}>
        如果你選擇或提供用戶識別碼，密碼或任何其他信息作為安全程序的一部分，你必須將這些信息視為保密信息。你不得將其透露給任何第三方。如果你的手機號碼用於帳戶登錄，你應確保在你的手機號碼被更改或交還流動電訊營運商時，通過在“賬戶資料”中登錄你的賬戶進行及時更新。這對於確保你的帳戶信息不會被可能已經獲得了使用你已交還的手機號碼權利的第三方存取非常重要。你須負責以這種特定的方式保護你的帳戶。我們對此不承擔任何責任。
      </p>
      <p className={styles.msg}>
        除非法律規定要求亞洲電視須保存你的個人資料一段特定的時間，亞洲電視只會將個人資料保存至達到收集個人資料之原來目的，或直接與其有關之目的為止。
      </p>
      <p className={styles.msg} style={{ height: 21 }}></p>
      <p className={styles.keynote}>直接促銷</p>
      <p className={styles.msg}>
        亞洲電視或將向你收集的個人資料用於發送消息、優惠、宣傳及市場報價用途，就此而言，本公司須要取得你的同意。我們可透過電郵、移動應用程式通知、社交媒體連結、短訊、文字／圖像／視像訊息、電話或郵件等方式聯絡你。本公司持有你的姓名、電郵地址、電話號碼、聯絡地址、社交媒體連結、產品及服務組合資料、交易模式及行為、瀏覽紀錄、節目收看習慣、個人興趣，本公司可使用有關資料作直接促銷我們的服務，以及以下我們的業務夥伴的產品及服務，或亞洲電腦應用程式商戶的產品或服務類別之用，包括但不限於食品及飲料、雜貨、家居用品、個人護理及保健、護膚及化妝品、母嬰用品、寵物用品、電子電器、家品家俬、吃喝玩樂、運動旅行、玩具圖書、電訊、媒體及娛樂、時裝、保險和財務產品類別及為慈善、文化、公益或康體目的索求捐贈或貢獻等你可能感興趣的產品、服務或項目。
      </p>
      <p className={styles.msg}>
        如果你不希望收到我們及合作夥伴發出的任何直接促銷通訊，可以隨時透過你的註冊賬戶或我們提供的取消訂閱連結更新你的喜好。在收到你通知後，我們將停止使用你的個人資料作直接促銷之用，並不會就此向你收費。
      </p>
      <p className={styles.msg} style={{ height: 21 }}></p>
      <p className={styles.keynote}>使用「曲奇」檔案 (COOKIES)</p>
      <p className={styles.msg}>
        「曲奇」檔案是一個當你進入本公司網頁及移動應用程式時存儲於你的電腦（或其他電子裝置）的小型文字檔。我們在本網頁使用「曲奇」檔案，以便：
      </p>
      <p className={styles.msg}>· 在你瀏覽本網頁及移動應用程式時辨認你的身分</p>
      <p className={styles.msg}>
        · 取得有關你喜好的資料、查閱及瀏覽行為、網上活動及互聯網的使用情況
      </p>
      <p className={styles.msg}>
        · 持續追蹤你購物籃內儲存的項目，協助你通過結賬的手續
      </p>
      <p className={styles.msg}>
        · 進行研究及統計分析，協助我們改善服務，更了解訪客及訪客的要求及興趣
      </p>

      <p className={styles.msg}>
        ·
        提供切合你個人興趣的廣告，更有效地進行我們、業務夥伴及廣告商的促銷及廣告計劃
      </p>
      <p className={styles.msg}>· 使你的網上活動更有效率，體驗更佳</p>
      <p className={styles.msg}>· 使安全措施更嚴密</p>
      <p className={styles.msg}>
        我們使用「曲奇」檔案取得的資料可能不含你的個人資料。儘管我們可能取得你的電腦或其他電子設備的資料（如IP地址、瀏覽器設定、瀏覽紀錄及／或其他互聯網紀錄資料），但未必可以識別你的身分。由於非個人資料與個人資料混雜，就私隱政策而言，我們視有關資料為個人資料。在若干情況下，我們可以收集你的個人資料，但必須經你填妥網上表格自願提供，或於你在我們的網頁及移動應用程式購買貨品或使用服務時收集。
      </p>
      <p className={styles.msg}>
        假如你不允許「曲奇」檔案的使用，可在互聯網瀏覽器及電子裝置上作出調整。如你停用「曲奇」檔案，即表明你知道可能無法使用我們網頁及移動應用程式的某些功能。
      </p>
      <p className={styles.msg} style={{ height: 21 }}></p>
      <p className={styles.keynote}>連線網頁</p>

      <p className={styles.msg}>
        本私隱政策聲明只適用於本公司網頁及移動應用程式。我們網頁及移動應用程式可能載有通往其他網址及網頁的連線點。
        每當你啟動任何此等連線網址，例如點選任何廣告客戶的連線欄目，你即已離開了我們的網址及移動應用程式；而你在離開我們網址及移動應用程式後向任何其他團體提供的任何個人資料或任何其他資料，一概不在本公司的管控範圍內。
        你須承擔一切瀏覽或使用其他網址的風險。
      </p>
      <p className={styles.msg} style={{ height: 21 }}></p>
      <p className={styles.keynote}>個人資料之查閱及更正</p>
      <p className={styles.msg}>
        如你對亞洲電視之私隱政策有任何疑問，或有意查閱或更正個人資料，你可以書面方式通知我們
        。
      </p>

      <p className={styles.msg}>
        按照條例，本公司保留權利就有關的查閱資料要求向你收取合理的費用。
      </p>
      <p className={styles.msg}>本私隱政策會定期審閱及於網站上更新。</p>
      <p className={styles.msg} style={{ height: 21 }}></p>
      <p className={styles.msg}>最近更新日期：2020年</p>
      <p className={styles.msg} style={{ height: 21 }}></p>
    </div>
  );
}
