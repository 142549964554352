import React, { useEffect, useState, createRef } from 'react';
import { connect } from 'dva';
import useScrollTop from '../../useHooks/useScrollTop';
import { RightOutlined } from '@ant-design/icons';
import CommentSetting from './components/CommentSetting';
import classnames from 'classnames';
import {
  getDetailResource,
  GetVideoPlayURL,
  collectResource,
  likeResource,
  saveVideoHistory,
  getADList,
  getADDetail,
  postADStat,
} from '@/api';
import { getAvatar, asyncHide } from '../../utils';
import Toast from '../../components/Toast';
import qs from 'querystring';
import Barrage from '../../components/Barrage';
import { decrypt_aes } from '../../utils/helper';
import styles from './index.module.scss';
import Player from '@/components/Player_';
import './index.scss';
import { message } from 'antd';

let timer;
// 每隔多少秒 获取一次弹幕
const COMMENT_TIME_INTERVAL = 10;
let prevEpisode = null;
const VIDEO_CLASS_NAME = 'video_player';
let commentTimer;
let timerfd23;
function Playera({
  dispatch,
  user = {},
  history,
  location,
  createComment,
  ...props
}) {
  user = user || {};
  const playerId = props.match.params.id; // string
  const searchObj = qs.parse(window.location.search.replace('?', ''));
  const [currentVideoData, setCurrentVideoData] = useState({});
  const [hasVideoUrl, setHasVideoUrl] = useState(false);
  const [episode, setEpisode] = useState(searchObj.episode * 1 || 1); //当前第几集
  const [source, setSource] = useState({});
  const [height, setHeight] = useState({});
  const [currentSrc, setCurrentSrc] = useState('');
  const [inputFocus, setInputFocus] = useState(false);
  const [advertUrl, setAdvertUrl] = useState({});
  const [barrageSetting, setBarrageSetting] = useState({
    on: false,
    searchInputVal: '',
    fontSize: 21,
    color: 'white',
    velocity: 1,
    opacity: 1,
    isShowSettingView: false,
    comment_interval: {},
  });
  const videoRef = createRef();
  const [showJj, setshowJj] = useState(false);
  const [legibilitySrc, setLegibilitySrc] = useState(null);
  const [videoStatus, setVideoStatus] = useState('pause');
  const [videoId, setVideoId] = useState('');
  const [videoTime, setVideoTime] = useState(0);
  const [durations, setDurations] = useState(0);
  const [advertTime, setAdvertTime] = useState(0);
  const {
    Name,
    CurNum,
    Videos,
    Year,
    CollectType = 2,
    LikeType = 2,
    ID,
    ResourceDetail: { Abstract, Starring } = {},
  } = currentVideoData;
  const leftRef = createRef();
  const rightRef = createRef();

  const getCurrentEpisodeData = () => {
    if (currentVideoData.Videos === undefined) return {};
    return currentVideoData.Videos[episode - 1];
  };

  const handleGetAD = (flag) => {
    const query = flag ? { ScreenBegin: 1 } : { ScreenPause: 1 };
    getADList(query).then((res) => {
      const [result] = res.Data || [];
      if (!result || (flag && user.Level === 2)) return;
      setAdvertUrl((his) => {
        const newHis = Object.assign({}, his);
        if (flag) {
          newHis['duration'] = result.Duration;
          newHis.begin = result.Video;
        } else {
          newHis.pause = result.Image;
        }
        newHis.advId = result.ID;
        newHis.jumpUrl = result.Goto;
        return newHis;
      });
    });
  };

  const setEpisodeUiHeight = () => {
    timer = setTimeout(() => {
      if (!document.getElementsByClassName('Player_episode__2cftA')) return;
      const videoDom = document.getElementsByClassName(styles.left)[0];
      const episodeDom = document.getElementsByClassName(
        'Player_episode__2cftA'
      )[0];
      if (!videoDom || !episodeDom) return;

      const videoHeight = videoDom.offsetHeight * 1;
      const episodeHeight = episodeDom.offsetHeight * 1 + 38 + 56 + 29 + 19;

      if (episodeHeight > videoHeight) {
        setHeight({
          height: videoHeight - 38 - 56 - 29 - 19,
        });
      }
    }, 4000);
  };
  const getResource = () => {
    (async function () {
      const res = await getDetailResource(playerId * 1);
      setCurrentVideoData(res.Resource);
      setTimeout(() => show(), 10);
    })();
  };
  const fasdfa = (currentTime) => {
    const dom = document.getElementsByClassName(VIDEO_CLASS_NAME)[0];
    if (dom === undefined) return;
    currentTime = currentTime || Math.floor(dom.currentTime);

    setBarrageSetting((cur) => {
      return {
        ...cur,
        on: true,
        comment_interval: {
          start_timer: currentTime,
          end_timer: currentTime + COMMENT_TIME_INTERVAL,
        },
      };
    });
    // clearTimeout(commentTimer);
  };
  const startComment = (type) => {
    if (type === 'immediately') fasdfa();
    else {
      timerfd23 = setTimeout(() => fasdfa(), 2500);
    }
    clearInterval(commentTimer);
    commentTimer = setInterval(() => {
      fasdfa();
    }, COMMENT_TIME_INTERVAL * 1000);
  };
  const stepComment = () => {
    clearTimeout(commentTimer);
    setBarrageSetting((cur) => {
      return {
        ...cur,
        on: false,
      };
    });
  };

  useEffect(() => {
    getResource();
    setEpisodeUiHeight();
    return () => {
      clearTimeout(timer);
      clearTimeout(timerfd23);
    };
  }, []);

  useScrollTop();

  const isPlayPower = (e) => {
    if (e.Permission === undefined) return false;
    return e.Permission === 2 && user.Level === 1;
  };

  const [isShowRight, setIsShowRight] = useState(true);

  const onRetract = () => {
    onPause();
    setIsShowRight(!isShowRight);
    setTimeout(() => {
      startComment();
    }, 100);
  };

  const isVip = (e) => {
    e = e || getCurrentEpisodeData();
    if (e.Permission === undefined) return false;
    return e.Permission === 2;
  };

  const isFufei = (e) => {
    if (e.Permission === undefined) return false;
    return e.Permission === 3;
  };

  const onSelectJi = (item, n) => {
    setLegibilitySrc(null);
    stepComment();
    if (isPlayPower(item)) {
      dispatch({
        type: 'modelState/setCharge',
        payload: {
          state: true,
          data: {
            buySomething: 'member',
          },
        },
      });
      return;
    }
    prevEpisode = episode;
    setEpisode(n);
  };

  const handleAdvTime = () => {
    const { advId } = advertUrl;
    if (advertTime && typeof advertTime === 'number' && advId) {
      postADStat({
        ID: advId,
        Type: 1,
        Data: advertTime,
      });
      setAdvertTime(0);
    }
  };

  useEffect(() => {
    if (Array.isArray(Videos)) {
      handleAdvTime();
      handleGetAD(true);
      setCurrentSrc('');
      getCurrentPlayerScr();
    }
  }, [episode, Videos]);

  const getVipIcon = (e) => {
    if (isVip(e)) {
      return (
        <img
          src={require('@/assets/images/vip.png').default}
          className={styles.vip_icon}
        />
      );
    }
    if (isFufei(e)) {
      return (
        <img
          src={require('@/assets/images/fu_fei.png').default}
          className={styles.vip_icon}
        />
      );
    }
    return null;
  };

  const onSullScreen = () => {
    setTimeout(() => {
      const rightRef = document.getElementsByClassName(styles.right)[0];
      const leftRef = document.getElementsByClassName(styles.left)[0];

      if (rightRef) rightRef.style.display = 'none';

      leftRef.className += ' ' + styles.sull_screen;
      dispatch({
        type: 'mussy/setItem',
        payload: {
          isShowTop: false,
          isShowBottom: false,
        },
      });
    }, 400);
    // leftRef.classList.add(styles.sull_screen);
  };

  const onExitFullscreen = () => {
    const rightRef = document.getElementsByClassName(styles.right)[0];
    const leftRef = document.getElementsByClassName(styles.left)[0];
    if (rightRef) rightRef.style.display = 'block';
    if (!leftRef) return;
    leftRef.classList.remove(styles.sull_screen);
    dispatch({
      type: 'mussy/setItem',
      payload: {
        isShowTop: true,
        isShowBottom: true,
      },
    });
  };

  // 切换清晰度
  useEffect(() => {
    if (legibilitySrc) {
      setCurrentSrc(legibilitySrc.src);
    }
  }, [legibilitySrc]);

  const onLike = async () => {
    const classList = document.getElementsByClassName(styles.zan)[0].classList;
    const isActive = classList.contains(styles.active);

    if (isActive) {
      classList.remove(styles.active);
    } else {
      classList.add(styles.active);
    }

    const res = await likeResource({
      UserID: user.ID,
      LikeType: !classList.contains(styles.active) ? 2 : 1,
      ResourceID: ID,
    });
    Toast(isActive ? '取消喜歡' : '喜歡成功');
  };
  const onCollect = async (e) => {
    const classList = document.getElementsByClassName(styles.shou)[0].classList;
    const isActive = classList.contains(styles.active);

    if (isActive) {
      classList.remove(styles.active);
    } else {
      classList.add(styles.active);
    }

    const res = await collectResource({
      UserID: user.ID,
      CollectType: !classList.contains(styles.active) ? 2 : 1,
      ResourceID: ID,
    });
    Toast(isActive ? '取消收藏' : '收藏成功');
  };

  const getCurrentPlayerScr = async () => {
    if (legibilitySrc !== null) {
      return legibilitySrc;
    }

    const { Videos = [] } = currentVideoData || {};
    const { ID: VideoID } = Videos[episode - 1] || {};
    if (!VideoID) return '';
    setVideoId(VideoID);
    setHasVideoUrl(false);
    const res = await GetVideoPlayURL({ VideoID, Aes: 1 });
    const { Data = {} } = res || {};
    const { URL, JobStatus, Hls480p, Hls720p, Hls1080p, JobIndex } = Data || {};
    const resURL = decrypt_aes(URL);
    const resHls480p = decrypt_aes(Hls480p);
    const resHls720p = decrypt_aes(Hls720p);
    const resHls1080p = decrypt_aes(Hls1080p);
    setCurrentSrc(JobStatus === 'COMPLETE' ? resHls720p : resURL);
    if (JobStatus === 'COMPLETE') {
      setSource({
        480: resHls480p,
        720: resHls720p,
        1080: resHls1080p,
      });
      if (JobIndex) setHasVideoUrl(true);
    }
  };

  const handleSaveRecord = () => {
    saveVideoHistory({
      ID: videoId,
      PlayTime: Math.floor(videoTime),
      Duration: Math.floor(durations),
    });
  };

  useEffect(() => {
    if (!videoId || videoStatus !== 'play') return;
    const handler = setTimeout(() => {
      handleSaveRecord();
      setTimeout(() => setVideoTime((e) => e + 15), 15000);
    }, 300);
    return () => clearTimeout(handler);
  }, [videoStatus, videoId, videoTime]);

  const getPrice = () => {
    if (user.Level === 2) return getCurrentEpisodeData().VipPrice / 100;
    return getCurrentEpisodeData().Price / 100;
  };

  const onPlay = (episode) => {
    startComment();
  };

  const onPause = () => {
    stepComment();
  };

  const getPlayer = () => {
    const arrVideos = currentVideoData.Videos;
    if (arrVideos === undefined) return;
    if (
      currentVideoData.Videos.length == 1 &&
      isPlayPower(currentVideoData.Videos[0])
    ) {
      return (
        <div className={styles.not_vip}>抱歉，該影視需要VIP會員才能觀看</div>
      );
    } else if (
      getCurrentEpisodeData().Permission === 3 &&
      getCurrentEpisodeData().JobIndex === undefined && !hasVideoUrl
    ) {
      return (
        <div className={styles.not_vip}>
          <p className={styles.title}>付费开启全片，会员半价</p>
          <div className={styles.btns}>
            <span
              onClick={() => {
                dispatch({
                  type: 'modelState/setCharge',
                  payload: {
                    state: true,
                    data: {
                      buySomething: 'resource',
                      videoID: getCurrentEpisodeData().ID,
                      price: getPrice(),
                    },
                    callback: () => {
                      history.push(location.pathname + '?episode=' + episode);
                      window.location.reload();
                    },
                  },
                });
              }}
              className={styles.current}
            >
              原价购买(HK${getPrice()})
            </span>
            {user.Level === 2 ? null : (
              <span
                onClick={() => {
                  dispatch({
                    type: 'modelState/setCharge',
                    payload: {
                      state: true,
                      data: {
                        buySomething: 'member',
                      },
                    },
                  });
                }}
              >
                成为会员
              </span>
            )}
          </div>
        </div>
      );
    } else if (arrVideos) {
      return (
        <Player
          onExitFullscreen={onExitFullscreen}
          onSullScreen={onSullScreen}
          src={currentSrc}
          source={source}
          advertUrl={advertUrl}
          onChangeLegibility={(str) => {
            setLegibilitySrc(str);
          }}
          videoRef={videoRef}
          inputFocus={inputFocus}
          onPlay={(event = {}) => {
            const { currentTime = 0, duration = 0, paused } = event;
            onPlay(episode);
            if (!paused && typeof paused === 'boolean' && duration) {
              setVideoTime(currentTime);
              setDurations(duration);
              setVideoStatus('play');
            }
          }}
          onPause={(res) => {
            if (res) {
              handleGetAD();
              setAdvertUrl({});
            }
            onPause();
            setVideoStatus('pause');
          }}
          onAdvert={(type, result) => {
            switch (type) {
              case 'jump': {
                const { advId, jumpUrl } = advertUrl;
                if (advId && jumpUrl) {
                  window.open(jumpUrl);
                  getADDetail({
                    params: {
                      ID: advId,
                    },
                  });
                }
                break;
              }
              case 'vip':
                dispatch({
                  type: 'modelState/setCharge',
                  payload: {
                    state: true,
                    data: {
                      buySomething: 'member',
                    },
                  },
                });
                break;
              case 'time': {
                const { advId } = advertUrl;
                if (advId) {
                  postADStat({
                    ID: advId,
                    Type: 1,
                    Data: result,
                  });
                }
                break;
              }
              case 'setTime':
                setAdvertTime(result);
                break;
              default:
                break;
            }
          }}
        />
      );
    }
  };
  const commentRef = createRef();
  const items_fjo23d = (className = '') => {
    return (
      currentVideoData.Categorys &&
      currentVideoData.Categorys.map((e) => (
        <span className={styles[className]} key={e.ID}>
          {e.Name}{' '}
        </span>
      ))
    );
  };
  const show = () => {
    setshowJj(!showJj);
    const targetDom = document.getElementsByClassName(styles['fdjsio2d'])[0];
    if (targetDom === undefined) return;
    const targetHeight = targetDom.offsetHeight + 30;
    const PlayDom = document.getElementsByClassName(styles['player'])[0];

    if (!showJj) {
      PlayDom.style.marginBottom = targetHeight + 150 + 'px';
      targetDom.style.bottom = -targetHeight + 'px';
      targetDom.style.zIndex = 0;
    } else {
      PlayDom.style.marginBottom = 150 + 'px';
      targetDom.style.bottom = -30 + 'px';
      targetDom.style.zIndex = -99;
    }
  };
  const getSwitchStyle = () => {
    if (barrageSetting.on) {
      return require('./images/on.png').default;
    } else {
      return require('./images/off.png').default;
    }
  };
  const onCreateComment = () => {
    if (!Barrage?.selfSend)
      return message.warning('弹幕发送失败，请稍后再试！');
    Barrage.selfSend({
      ...barrageSetting,
      content: barrageSetting.searchInputVal,
      color: barrageSetting.color,
    });
    setBarrageSetting((cur) => {
      return {
        ...cur,
        searchInputVal: '',
      };
    });
    dispatch({
      type: 'comment/createComment',
      payload: {
        Comment: {
          Content: barrageSetting.searchInputVal,
          TargetType: 5,
          TargetID: currentVideoData.ID,
          VideoTime: Math.floor(
            document.getElementsByClassName('video_player')[0].currentTime
          ),
          Color: barrageSetting.color,
          VideoID: getCurrentEpisodeData().ID,
        },
      },
    });
  };
  return (
    <div className={styles.player}>
      <div
        ref={leftRef}
        id='video_play'
        className={classnames(styles.left, styles.video)}
      >
        <div
          onClick={onRetract}
          className={styles.retract}
          style={isShowRight ? {} : { transform: `rotate(180deg)`, right: '0' }}
        >
          <RightOutlined />
        </div>
        <div
          className={styles.func_Fd9023}
          style={{ display: !currentVideoData?.ID ? 'none' : 'flex' }}
        >
          <div className={styles.btns}>
            <div className={styles.func_left}>
              <div
                onClick={() => onLike()}
                className={classnames(styles.zan, {
                  [styles.active]: LikeType === 1,
                })}
              >
                <div className={styles.img} />
                <span>點贊</span>
              </div>
              <div
                onClick={(e) => onCollect(e)}
                className={classnames(styles.shou, {
                  [styles.active]: CollectType === 1,
                })}
              >
                <div className={styles.img} />
                <span>收藏</span>
              </div>
            </div>
            <div className={styles.func_right}>
              <div className={styles.comment_btns}>
                <img
                  onClick={() => {
                    barrageSetting.on
                      ? stepComment()
                      : startComment('immediately');
                  }}
                  onMouseEnter={(e) => {
                    if (barrageSetting.on === false) {
                      e.target.src = require('./images/h_off.png').default;
                    }
                  }}
                  onMouseLeave={(e) => {
                    if (barrageSetting.on === false) {
                      e.target.src = require('./images/off.png').default;
                    }
                  }}
                  src={getSwitchStyle()}
                  className={classnames(styles.comment_off_on, {
                    [styles.on]: barrageSetting.on === false,
                  })}
                />
                <div
                  className={styles.comment_setting}
                  onMouseLeave={function (e) {
                    asyncHide({
                      dom: document.getElementsByClassName(
                        'CommentSetting_comment_setting__xjvOQ'
                      )[0],
                      targetDoms: [
                        document.getElementsByClassName(
                          styles.comment_setting
                        )[0],
                        document.getElementsByClassName(
                          'CommentSetting_comment_setting__xjvOQ'
                        )[0],
                      ],
                      callback() {
                        setBarrageSetting((cur) => {
                          return {
                            ...cur,
                            isShowSettingView: false,
                          };
                        });
                      },
                    });
                  }}
                  onMouseEnter={() => {
                    setBarrageSetting((cur) => {
                      return {
                        ...cur,
                        isShowSettingView: true,
                      };
                    });
                  }}
                >
                  {barrageSetting.isShowSettingView ? (
                    <CommentSetting
                      isVip={isVip(user)}
                      userData={user}
                      currentColor={barrageSetting.color}
                      onChangeColor={(color) => {
                        setBarrageSetting((cur) => {
                          return {
                            ...cur,
                            color: color,
                          };
                        });
                      }}
                    />
                  ) : null}
                </div>
              </div>
              <div className={styles.comment_input_bar}>
                <img src={getAvatar()} className={styles.head_portrait} />
                <input
                  value={barrageSetting.searchInputVal}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) onCreateComment();
                  }}
                  onChange={(e) => {
                    setBarrageSetting((cur) => {
                      return { ...cur, searchInputVal: e.target.value };
                    });
                  }}
                  onBlur={() => setInputFocus(false)}
                  onFocus={() => setInputFocus(true)}
                  placeholder='來發彈幕吧~'
                  className={styles.comment_input}
                />
                <div className={styles.btn} onClick={onCreateComment}>
                  發表
                </div>
              </div>
            </div>
          </div>
          <div className={classnames(styles.synopsis)}>
            <p className={styles.one_F}>
              {Name}: 第{episode}集
            </p>
            <p className={styles.two_Fd}>
              <span
                style={{ cursor: 'pointer' }}
                className={classnames({ [styles.active]: showJj })}
                onClick={() => show()}
              >
                <img
                  src={
                    showJj
                      ? require('./images/a_jj.png').default
                      : require('./images/jj.png').default
                  }
                />
                <span className={styles.fsdjuio}>简介</span>
                {showJj ? (
                  <img
                    className={styles.img}
                    src={require('./images/a_qw.png').default}
                  />
                ) : (
                  <img
                    className={styles.img}
                    src={require('./images/qw.png').default}
                  />
                )}
              </span>
              <span className={styles.fdjsoi}>全{CurNum}集</span>
              {items_fjo23d('fdsfdsdjio')}
            </p>
            <ul
              className={classnames(styles.fdjsio2d, {
                [styles.hide]: !showJj,
              })}
            >
              <li>
                <span className={styles.right_Fji2o}>导演:</span>{' '}
              </li>
              <li>
                <span className={styles.right_Fji2o}>主演:</span>
                {Starring}
              </li>
              <li>
                <span className={styles.right_Fji2o}>剧情:</span>
                {Abstract}
              </li>
            </ul>
          </div>
        </div>
        <Barrage
          commentRef={commentRef}
          videoId={getCurrentEpisodeData().ID}
          id={currentVideoData.ID}
          {...barrageSetting}
        />
        {getPlayer()}
      </div>
      {isShowRight ? (
        <div ref={rightRef} className={classnames(styles.right, styles.datas)}>
          <div className={classnames(styles.one)}>
            <span className={classnames(styles.name)}>{Name}</span>
            <div className={styles.btns}>
              {/* <img src={require('@/assets/images/bf.png').default} /> */}
              {/* <img src={require('@/assets/images/sc.png').default} /> */}
            </div>
          </div>
          <p className={styles.sjdfio}>
            {currentVideoData.Area && currentVideoData.Area.Name} / {Year} /{' '}
            {items_fjo23d()} / {CurNum}集全
          </p>
          <p className={styles.sjdfiod}>選集</p>
          <ul className={'Player_episode__2cftA'} style={{ ...height }}>
            {currentVideoData.Videos &&
              currentVideoData.Videos.map((e, i) => {
                let item = e;
                return (
                  <li
                    onClick={() => onSelectJi(item, i + 1)}
                    className={classnames(styles.item, {
                      [styles.active]: i + 1 === episode,
                    })}
                    key={i}
                  >
                    {i + 1}
                    {getVipIcon(item)}
                  </li>
                );
              })}
          </ul>
        </div>
      ) : null}
    </div>
  );
}
export default connect((state) => ({
  user: state.user.userData,
}))(Playera);
