import React, { useState, useEffect } from 'react';
import Image from '@/components/Image';
import { connect } from 'dva';
import LoadingLine from '@/components/LoadingLine';
import { onToPlayer } from '../../../../utils';
import './index.scss';

let timer = null;

const getDefaultImgUrl = (banner) => {
  if (banner.length) {
    return banner.map((item) => ({
      url: item.BannerPc || item.BannerPreview,
      path: item.ID,
    }));
  }
  return [{ path: '', url: '' }];
};


function TakeTurns({
  screen: { home: { banner = []} },
  loading,
  history,
  dispatch,
} = {}) {
  const ims = getDefaultImgUrl(banner);
  const [loading_img, setloading_img] = useState(true);
  const [imgUrl, setImgUrl] = useState({
    index: 0,
    url: ims[0].url,
    path: ims[0].path,
  });

  const getNewUrl = () => {
    if (ims.length == 1 || imgUrl.index == ims.length - 1)
      return {
        index: 0,
        url: ims[0].url,
        path: ims[0].path,
      };
    let res;
    try {
      res = {
        index: imgUrl.index + 1,
        url: ims[imgUrl.index + 1].url,
        path: ims[imgUrl.index + 1].path,
      };
    } catch (e) {
      res = {
        index: 0,
        url: ims[0].url,
        path: ims[0].path,
      };
    }
    return res;
  };

  useEffect(() => {
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      setImgUrl(getNewUrl());
    }, 2500);
    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <div className='take-turns'>
      <Image
        onLoaded={(_) => setloading_img(false)}
        onClick={() =>
          onToPlayer({
            data: { ID: imgUrl.path },
            dispatch: dispatch,
            history: history,
          })
        }
        src={imgUrl && imgUrl.url}
      ></Image>
      <div className='function'>
        {/* <div className='btn'>

                </div>
                <div className='btn'></div> */}
      </div>

      <div className='loading_line-jds2lk'>
        {' '}
        {loading.effects['screen/getBanner'] ||
        loading.effects['screen/getClassifys'] ||
        loading_img ? (
          <LoadingLine />
        ) : null}
      </div>
    </div>
  );
}

export default React.memo(connect((state) => state)(TakeTurns));
