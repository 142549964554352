import React from 'react';
import styles from './index.module.scss';
export default function Clause() {
  return (
    <div className={styles.clause}>
      <p className={styles.title}>亞洲電視經典有限公司《服務條款》</p>
      <img src={require('./image/1.png').default} />
      <img src={require('./image/2.png').default} />
      <p className={styles.two}>
        <p className={styles.msg}>1.3</p>
        <p className={styles.msg}>
          手橙鹰用程式︰按一下用卢介面右下方的[帐币]暹项﹐登入後可於[盯開组合]内查看
        </p>
      </p>
      <p className={styles.two}>
        <p className={styles.msg}>1.4</p>
        <p className={styles.msg}>
          平板鼋淌鹰用程式∶按一下用巨介面右下方的[帐户]暹项﹐登入後可於[盯開组合]内查看
        </p>
      </p>
      <p className={styles.one}>2. 免費試用</p>
      <p className={styles.two}>
        <p className={styles.msg}>2.1</p>
        <p className={styles.msg}>
          您的ATV畲员资格可以先緃免费轼用開始·畲晨瓷格免费轼用期票鲑册踌指定日期或另行规定期限﹐其目的是镶新畲员和某些前畲具封用服鸦
        </p>
      </p>
      <p className={styles.two}>
        <p className={styles.msg}>2.2</p>
        <p className={styles.msg}>
          免费封用期资格由ATV全榷酌情泱定﹐我阴可能畲限制资格﹐以避免婺生免费眦用蓄用的情况·我阴保留取淌免费轼用期资格之榷利﹐如果我咐眩定您不符镝用资格踌﹐将詹暂停您的幅卢·具倩琨有或最近刚取得ATV畲具资格的畲具不符合资格·我阴可能鲁使用奘置藏别碣·付款方式或目前或最近刚取得ATV畲员资格的帐卢所使用的鼋鄄地址的资惑﹐来碓定是否具借资格Р舆其他慑惠一起使用畴可能畲有限制规定·
        </p>
      </p>
      <p className={styles.two}>
        <p className={styles.msg}>2.3</p>
        <p className={styles.msg}>
          免费试用期结束後﹐除非闇下缮缭钉瞒﹐否则不向您收取任何收费.
        </p>
      </p>
      <p className={styles.one}>3. 收费</p>
      <p className={styles.two}>
        <p className={styles.msg}>3.1</p>
        <p className={styles.msg}>
          收费遗期·ATⅣ盯髑组合畲具费及针剿您使用本服矜可能奎生的任何其他费用﹐例如税金典可能的交易手縯费﹐将舍按月封愿您畲晨资格開始付款部分的日期﹔依照您的付款方式向您收取·在某些情况下﹔您的付款日可能鲁缵勤﹐例如﹐您的付款方式血未结算成功﹐或您的付费含晨资格敞用日期在某固月份没有勤愿日期·前往您的[盯開组合](方法睛参開本服矜倏款第1倏)﹐即可查看您的下一付款日期·
        </p>
      </p>
      <p className={styles.two}>
        <p className={styles.msg}>3.2</p>
        <p className={styles.msg}>
          付款方式·如果由於遗期·资金不足或其他原因樽致付款失敢﹐且您没有更改付款方式或取淌幔币﹐则我阴可暂停您封服矜的使用﹐直至我阴取得有效的付款方式·凿您更新付款方式赔﹐您即授榷我胭以更新後的付款方式继缜向您收费﹐且您仍须蒍任何未收取的金额负责·此情况可能造成您的付款日期爱更·针到某些付款方式﹔豉行檄媾可能舍向您收取特定费用﹐例如跨国交易手縯费或其他针到您的付款方式之虚理所收取的黄用·尝地税额可能因所使用的付款方式而有所不同·群情o豁韵您的付款方式服移提供者
        </p>
      </p>
      <p className={styles.two}>
        <p className={styles.msg}>3.3</p>
        <p className={styles.msg}>
          取淌盯髑组合自员·您可随膊取消您的ATV钉開组合畲具资格﹐在眷月收费插期结束前﹐可僊绩使用本服移·在相闟法律斯可之i圈内﹐付款不可退款﹐且到於不足一但月的合晨期或未都赏的ATⅣ内容，我膺不予退款或抵用·付费畲具於收费逶期结束或停止付款後﹐其盯開组合畲晨资格将畲被更改蒍免费畲员·睛先了解您曾粳於哪棰平台雉行付款∶使用手檄平台付款的盯髑组合畲昊如要取消盯開﹐睛根褫手橙作巢系统前往指定毁定夏面﹐亚按照指示取淌盯開∶使用搁站平台付款的盯髑组合自霓如要取消盯髑﹐可於Paypal眼卢的毅定取淌有嗣盯闟之付费﹔详情睛酪韵您的付款方式服移提供者·
        </p>
      </p>
      <p className={styles.two}>
        <p className={styles.msg}>3.4</p>
        <p className={styles.msg}>
          固闇幌巨·若要闟開帐后﹐可以直接鼋鄄至CS@hkatv.com或致鼋29928686舆客卢服鸦主任睇胳﹐我阴将有毒人直接蒍你服務。
        </p>
      </p>
      <p className={styles.two}>
        <p className={styles.msg}>3.5</p>
        <p className={styles.msg}>
          變更價格與服務方案。我們可隨時變更服務方案與價格；但任何價格或服務方案變更要在通知您至少
          30 天後方能生效。
        </p>
      </p>
      <p className={styles.one}>4. ATV 服務</p>
      <p className={styles.two}>
        <p className={styles.msg}>4.1</p>
        <p className={styles.msg}>
          您必須年滿 18
          歲或達到您所在區域、地區或國家的法定成年年齡，方可成為本服務的會員。未成年人須在成年人監督下使用本服務。
        </p>
      </p>
      <p className={styles.two}>
        <p className={styles.msg}>4.2</p>
        <p className={styles.msg}>
          本服務及透過本服務所觀賞的任何內容僅供個人使用，不可用於商業用途。在您的
          ATV
          會員資格有效期間內，我們授予您有限、非專屬、不得轉讓的權利以使用本服務使用權並觀賞
          ATV
          內容。除上述有限許可外，我們不向您轉讓任何權利、所有權或利益。您同意不使用本服務進行公開播放。
        </p>
      </p>
      <p className={styles.two}>
        <p className={styles.msg}>4.3</p>
        <p className={styles.msg}>
          您主要可透過您的帳戶所在國家或地區觀看本服務，而且只能在我們有提供服務並授權此類內容之地區觀賞。可提供觀賞的內容因地域而異，且隨時變更。您可同時觀賞的裝置數目取決於您所選擇的訂閱方案，詳情請見您的[訂閱組合]頁面(方法請參閱本服務條款第1條)。
        </p>
      </p>
      <p className={styles.two}>
        <p className={styles.msg}>4.4</p>
        <p className={styles.msg}>
          您同意根據所有適用法律、規則和規範或針對此服務或內容的其他使用限制規定使用本服務，包括與其相關的所有特色和功能。您同意不存檔、複製、散布、修改、展示、公開播放、出版、授權、製作衍生作品、出售或使用（除非本《服務條款》明確授權）本服務所含的內容和資訊或從本服務取得的內容和資訊。您還同意不：規避、移除、更改、停用、降級或妨礙本服務的任何內容保護措施；使用任何網路機器人(robot)、網路蜘蛛
          (spider)、scraper程式或其他自動方式來使用本服務；反編譯、逆向工程或解譯透過本服務取得的任何軟體或其他產品或程式；插入任何程式碼或產品或以任何方式操控本服務的內容；或使用任何資料探勘、資料收集或資料擷取方法。此外，您同意不上傳、發佈、以電郵傳送或以其他方式發送或傳輸任何旨在干擾、破壞或限制與本服務相關的任何電腦軟體或硬體或電信設備功能的資料，包括任何軟體病毒或其他任何電腦程式碼、檔案或程式。如果您違反本《服務條款》或參與非法或詐欺使用本服務，則我們可終止或限制您使用本服務。{' '}
        </p>
      </p>
      <p className={styles.one}>5. 網站內容</p>
      <p className={styles.two}>
        <p className={styles.msg}>5.1</p>
        <p className={styles.msg}>
          「原狀」及屬「原供應」狀態。於適用法律允許的最大範圍內，我們不會對所提供的內容作任何形式的陳述或保證（暗示或明示），同時該等內容不應作為特定的商業、金融、業務或法律意見。您僅能以本服務特意提供的功能及本服務條款所允許的情況下連接ATV內容供您作參考及個人的、非商業用途。除非有明確授權，我們及相關的內容提供者保留所有本服務及內容的權利。
        </p>
      </p>
      <p className={styles.two}>
        <p className={styles.msg}>5.2</p>
        <p className={styles.msg}>
          您明白當您使用本服務時，您可能會接觸到不同來源的ATV內容，而我們不會就該等內容的準確性、用處、安全性，或其知識產權負責。您進一步明白及確認，當您使用本服務時，您可能會接觸到不準確、您認爲具冒犯性、不雅、或令您反感的ATV內容。就該等內容，您同意放棄，並謹此放棄向我們提出任何（不論是法律上或衡平法上）的申索或尋求補償的權利。於適用法律允許的最大範圍內，您同意彌償我們，並保護我們免受任何基於或有關您使用本服務所引致我們蒙受的一切任何由第三方提出的索償、費用或要求。
        </p>
      </p>
      <p className={styles.two}>
        <p className={styles.msg}>5.3</p>
        <p className={styles.msg}>
          在本協議及/或您就本服務之使用終止或屆滿的情況下，此第5項條款仍繼續有效。
        </p>
      </p>
      <p className={styles.one}>6. 知識產權</p>
      <p className={styles.two}>
        <p className={styles.msg}>6.1</p>
        <p className={styles.msg}>
          您同意我們擁有並保留本服務及/或ATV內容的所有權，我們及內容的提供者擁有及保留對相關內容的所有權利。所有於本服務、ATV內容及/或ATV平台內的商標、服務標示及標誌（“標誌”）均屬我們擁有，或我們獲得授權使用。
        </p>
      </p>
      <p className={styles.two}>
        <p className={styles.msg}>6.2</p>
        <p className={styles.msg}>
          您確認本服務、ATV內容及/或標誌受到版權、商標法及其他知識產權法律所保護。您進一步同意，除您提供的內容外，您被授予的是非獨家、不可轉讓及有限度的授權（沒有轉授權），連接及使用本服務及/或ATV內容；並且，您會遵守本協議的條款只以串流方式連接及使用本服務及/或ATV內容。
        </p>
      </p>
      <p className={styles.two}>
        <p className={styles.msg}>6.3</p>
        <p className={styles.msg}>
          除非我們另有明確說明及除您提供的內容外，就您於或有關於本服務、ATV內容，及當中所載的材料進行的活動，都不會致使任何知識產權轉讓予您，也不會授權您行使任何知識產權的權利。
        </p>
      </p>
      <p className={styles.two}>
        <p className={styles.msg}>6.4</p>
        <p className={styles.msg}>
          倘若您侵犯任何我們的知識產權或任何人士的其他權利，我們明確保留向您採取行動的權利。
        </p>
      </p>
      <p className={styles.two}>
        <p className={styles.msg}>6.5</p>
        <p className={styles.msg}>
          在本協議及/或您就本服務之使用終止或屆滿的情況下，此第6項條款仍繼續有效。
        </p>
      </p>
      <p className={styles.one}>7. 密碼與帳戶存取</p>

      <p className={styles.msg}>
        建立 ATV 帳戶且其付款方式已完成扣款的會員（「帳戶所有人」）可存取並控制
        ATV 帳戶以及用以存取本服務的 ATV
        相容裝置。為保持對帳戶的控制並防止任何人存取帳戶（包括帳戶的觀賞記錄的資訊），帳戶所有人應掌握
        ATV
        相容裝置之控制權，不得將其帳戶的密碼或付款方式的詳細資料洩漏給任何人。對於您向我們提供的帳戶相關資訊，您有責任更新並保持其準確性。我們可能會終止或暫停您的帳戶，以便保護您、ATV
        或合作夥伴免受身份盜竊或其他詐欺活動的損害。
      </p>
      <p className={styles.one}>8. 擔保與責任限制</p>
      <p className={styles.msg}>
        本服務是以「現狀」提供，不提供任何保固或條件。特別是，本服務不可能不受到干擾或不會出現錯誤。您同意放棄對我們請求任何特殊、間接及衍生性的損害賠償。這些條款不限制您依據居住國家/地區法律所享有的任何不可放棄的保證或消費者保護權。
      </p>
      <p className={styles.one}>9. 免責聲明</p>
      <p className={styles.two}>
        <p className={styles.msg}>9.1</p>
        <p className={styles.msg}>
          在任何ATV平台上的任何視頻、數據、文章、至第三方平台之連結和其他資料（統稱「資料」）皆以「現況」形式提供，並只作提供資訊之用。
        </p>
      </p>
      <p className={styles.two}>
        <p className={styles.msg}>9.2</p>
        <p className={styles.msg}>閣下使用ATV平台，即視為知悉和同意：</p>
        <img src={require('./image/6.png').default} />
      </p>
      <img src={require('./image/10.png').default} />
      <img src={require('./image/11.png').default} />
      <img src={require('./image/w.png').default} />
    </div>
  );
}
