let envObj;
if (process.env.NODE_ENV === 'development') {
  envObj = {
    // baseurl: 'https://srv-news.hkatv.vip',
    baseurl: 'https://srv-news-test.hkatv.vip',
  };
}

if (process.env.NODE_ENV === 'production') {
  envObj = {
    // baseurl: 'https://srv-news.hkatv.vip',
    baseurl: 'https://srv-news-test.hkatv.vip',
  };
}

export default envObj;
