import React from 'react';
import { connect } from 'dva';
import styles from './index.module.scss';

function Footer({ goYS, goZC }) {
  const goToYS = () => {
    goZC();
  };
  const goToWT = () => {
    goYS();
  };
  return (
    <div className={styles['footer_fdfsd']}>
      <div className={styles['content_fdf']}>
        <div className={styles['a']}>
          <ul>
            <li className={styles.msg_text_box}>
              <div className={styles.msg_text}>手機版下載</div>
              <img
                onClick={() =>
                  window.open(
                    'https://apps.apple.com/cn/app/atv-%E4%BA%9E%E6%B4%B2%E9%9B%BB%E8%A6%96/id1262995639'
                  )
                }
                src={require('./image/apple_app.png').default}
              />
              <img
                onClick={() =>
                  window.open(
                    'https://play.google.com/store/apps/details?id=com.hkatv.android'
                  )
                }
                src={require('./image/google_app.png').default}
              />
            </li>
            <li className={styles.msg_text_box_s}>
              <div className={styles.msg_text_s}>
                如安卓流動設備沒有Google Play
              </div>
              <img
                onClick={() =>
                  window.open('https://atv-cdn.hkatv.vip/atv-app-apk/HKATV.apk')
                }
                src={require('./image/an.png').default}
              />
            </li>
          </ul>
        </div>
        <div className={styles['a']}>
          <ul>
            <li className={styles.msg_text_box}>
              <div className={styles.msg_text}>TV版下載</div>
              <img
                onClick={() =>
                  window.open(
                    'https://play.google.com/store/apps/details?id=com.asiatv.box'
                  )
                }
                src={require('./image/google_app.png').default}
              />
            </li>
            <li className={styles.msg_text_box_s}>
              <div className={styles.msg_text_s}>
                如TV設備沒有Google Play，請直接下載，U盤安裝
              </div>
              <img
                onClick={() =>
                  window.open('https://atv-cdn.hkatv.vip/stb-app/ATVBox.apk')
                }
                src={require('./image/tv.png').default}
              />
            </li>
          </ul>
        </div>
        <div className={styles.two_func}>
          <div className={styles.fjdsiof}>
            <p style={{ textAlign: 'center', opacity: '0.8' }}>
              <span>電郵： cs@hkatv.com</span>
              <span>抖音電郵： douyin_cs@hkatv.com</span>
              {/* <span>
                專人接聽服務時間：
                星期一至五，上午9時至下午6時，星期六、日及公眾假期休息
              </span> */}
            </p>
            <p className={styles.fdsjifo23} style={{ textAlign: 'center' }}>
              <span onClick={() => goToYS()} style={{ cursor: 'pointer' }}>
                隱私政策 |
              </span>
              <span onClick={() => goToWT()} style={{ cursor: 'pointer' }}>
                服務條款 |
              </span>
              <span>常見問題</span>
              <span className={styles.msg_text}>
                © 版權所有2021 亞洲電視經典有限公司
              </span>
              <img
                onClick={() =>
                  window.open('https://www.facebook.com/atvhongkong/')
                }
                src={require('./image/facebook.png').default}
              />
              <img
                onClick={() => window.open('https://www.instagram.com/hk_atv/')}
                src={require('./image/instagram.png').default}
              />
              <img
                onClick={() =>
                  window.open('https://www.youtube.com/c/atvhongkong')
                }
                src={require('./image/play.png').default}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default connect(null, (dispatch) => ({
  goYS() {
    dispatch({
      type: 'modelState/setExplain',
      payload: {
        state: true,
        data: {
          contentName: 'clause',
        },
      },
    });
  },
  goZC() {
    dispatch({
      type: 'modelState/setExplain',
      payload: {
        state: true,
        data: {
          contentName: 'policy',
        },
      },
    });
  },
}))(Footer);
